import React, { useState } from 'react';
import axios from 'axios';
import { Container, CssBaseline, Box, Typography, TextField, Button, FormControl, InputLabel, MenuItem, Select, AppBar, Toolbar, IconButton } from '@mui/material'; // Import MUI components
import DogeLogo from "../src/images/doge.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';


const SearchApp = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [searchStatus, setSearchStatus] = useState(null);

  const unspentApi = axios.create({
    baseURL: 'https://unspent.dogeord.io/api/v1/address/unspent/',
    timeout: 100_000,
  });
  
  function removeDivisibility(number, divisor) {
    // Check if the divisor is zero, if so return the original number
    if (divisor === 0) {
        return number;
    }
    
    // Convert number and divisor to integers to avoid floating point arithmetic issues
    number = parseInt(number);
    divisor = parseInt(divisor);
    
    // Convert number to string to manipulate the digits
    let numberStr = number.toString();
    
    // Check if the number is divisible by the divisor
    if (number % divisor === 0) {
        // Calculate the number of trailing zeroes to remove
        let numZerosToRemove = 0;
        while (numZerosToRemove < numberStr.length && numberStr[numberStr.length - 1 - numZerosToRemove] === '0') {
            numZerosToRemove++;
        }
        
        // Remove the trailing zeroes
        numberStr = numberStr.slice(0, numberStr.length - numZerosToRemove);
        
        // Convert the modified string back to a number
        return parseInt(numberStr);
    } else {
        // If not divisible, return the original number
        return number;
    }
}

  async function fetchAllUnspentOutputs(walletAddress) {
    let page = 1; // Start from the first page
    let allUnspentOutputs = []; // Array to hold all unspent outputs
    let hasMoreData = true; // Flag to keep the loop running until no more data is available
  
    while (hasMoreData) {
      try {
        // console.log(`Fetching unspent outputs for page ${page}...`);
        setSearchStatus(`Fetching Unspent Outputs - Page ${page}...`)
        // Fetch data from the API for the given page
        const response = await unspentApi.get(`/${walletAddress}/${page}`);
        const outputs = response.data.unspent_outputs;
  
        // Check if the response contains any unspent outputs
        if (outputs && outputs.length > 0) {
          // Map and concatenate the current page's data to the total
          const mappedOutputs = outputs.map((output) => ({
            txid: output.tx_hash,
            vout: output.tx_output_n,
            script: output.script,
            satoshis: Number(output.value),
          }));
  
          allUnspentOutputs = allUnspentOutputs.concat(mappedOutputs);
          page++; // Increment the page number to fetch the next page
        } else {
          hasMoreData = false; // No more data to fetch, exit the loop
        }
      } catch (error) {
        console.error("Error fetching unspent outputs:", error);
        break; // Exit the loop in case of an error
      }
    }
  
    return allUnspentOutputs; // Return the collected unspent outputs
  }

  async function getDunesForUtxos(hashes) {
    const ordApi = axios.create({
      baseURL: 'https://ord.dunesprotocol.com/',
      timeout: 100_000,
    });
  
    try {
      const response = await ordApi.get(`/outputs/${hashes.join(",")}`);
      const parsed = response.data;
  
      const dunes = [];
  
      parsed.forEach((output) => {
        if (output.dunes.length > 0) {
          dunes.push({ dunes: output.dunes, utxo: output.txid });
        }
      }, []);
  
      return dunes;
    } catch (error) {
      console.error("Error fetching or parsing data:", error);
      throw error;
    }
  }


  const handleSearch = async () => {
    try {
      setSearchResult(null);
      setSearchStatus(null);
        const utxos = await fetchAllUnspentOutputs(searchTerm);
        let balance = 0;
        const utxoHashes = utxos.map(utxo => `${utxo.txid}:${utxo.vout}`);
        const chunkSize = 50; // Size of each chunk
    
        // Function to chunk the utxoHashes array
        const chunkedUtxoHashes = [];
        for (let i = 0; i < utxoHashes.length; i += chunkSize) {
          chunkedUtxoHashes.push(utxoHashes.slice(i, i + chunkSize));
        }
    
        // Process each chunk
        for (const chunk of chunkedUtxoHashes) {
          const allDunes = await getDunesForUtxos(chunk);
    
          for (const dunesInfo of allDunes) {
            // console.log(JSON.stringify(dunesInfo));
            for (const singleDunesInfo of dunesInfo.dunes) {
              const [name, { amount,divisibility  }] = singleDunesInfo;
              
              if (name === selectedOption) {
                
                balance += removeDivisibility(Number(amount), divisibility);
              }
            }
          }
        }
        setSearchResult(balance.toString())
        // Output the total balance
        console.log(`${balance.toString()} ${selectedOption}`);

    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error
    }
  };

  return (
    <>
      <CssBaseline />
      <AppBar position="static" sx={{ boxShadow: 'none' }}>

        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            DunesBot
          </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="twitter"
            href="https://twitter.com/DoginalToadz"
            target="_blank"
          >
<FontAwesomeIcon icon={faTwitter}/>
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="discord"
            href="https://discord.gg/kJPcCxC9"
            target="_blank"
          >

<FontAwesomeIcon icon={faDiscord}/>

          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 4,
          }}
        >
          <img
            src={DogeLogo}
            alt="Dogecoin Logo"
            style={{ width: '40%', marginBottom: 5 }}
          />
          <Typography variant="h4" fontStyle={"italic"}>Dunes Balance Checker</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="search"
            label="Dogecoin Address"
            name="search"
            autoFocus
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-option-label">Select a DUNES</InputLabel>
            <Select
              margin="normal"
              required
              fullWidth
              label="Dogecoin Address"
              id="select-option"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <MenuItem value="WHO•LET•THE•DUNES•OUT">WHO•LET•THE•DUNES•OUT</MenuItem>
              <MenuItem value="THEDOGEFATHER">THEDOGEFATHER</MenuItem>
              <MenuItem value="JACKSONPALMER">JACKSONPALMER</MenuItem>
              <MenuItem value="DUNES•ARE•SANDY">DUNES•ARE•SANDY</MenuItem>
              <MenuItem value="ZZZZZZZZZZZZ">ZZZZZZZZZZZZ</MenuItem>
              <MenuItem value="ONEDUNE•ONELAMBO">ONEDUNE•ONELAMBO</MenuItem>
              <MenuItem value="PEPE•FEELS•GOOD">PEPE•FEELS•GOOD</MenuItem>
              <MenuItem value="MUCHDOGEHALLA">MUCHDOGEHALLA</MenuItem>
              <MenuItem value="CASEY•FINE•QUALITY">CASEY•FINE•QUALITY</MenuItem>
              <MenuItem value="DUNECHRONICLE">DUNECHRONICLE</MenuItem>
              <MenuItem value="DUNE•DELTA•GOAT">DUNE•DELTA•GOAT</MenuItem>
              <MenuItem value="DOGECOIN•DUNES">DOGECOIN•DUNES</MenuItem>
              {/* Add more options if needed */}
            </Select>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSearch}
          >
            Search Dunes
          </Button>
        </Box>

        {searchStatus && searchResult === null && (
          <Box sx={{ mt: 3 }}>
            <Typography align="center">{searchStatus}</Typography>
          </Box>
        )}

        {searchResult && (
          <Box sx={{ mt: 1, display: "flex", justifyContent: "center"}}>
            <Typography variant="h6" fontWeight={'bold'}>Balance - {searchResult} {selectedOption}</Typography>
          </Box>
        )}
      </Container>
    </>
  );
};

export default SearchApp;
